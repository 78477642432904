<template>
    <div id="contents" class="contents vmd page">
        <div class="content_head">
            <!-- s: 페이지 타이틀 -->
            <div class="page_ttl_wrap">
                <div class="channel_list_type">
                    <p>우리매장 핫딜</p>
                </div>
                <div class="channel_list_num">
                    <h2>채널 1</h2>
                </div>
                <div class="page_ttl">
                    <div class="editor_area">
                        <h2>우리매장 핫딜 VMD</h2>
                        <!-- Dev : editor_btn 클릭시 -->
                        <button class="editor_btn">
                            <img src="../../../assets/img/common/vdm_search.png" alt="">
                        </button>
                    </div>
                    <!--  Dev : editor_btn 클릭시 .page_ttl에 input_area로 적용-->
                    <!--          <div class="input_area inline">-->
                    <!--            <label></label>-->
                    <!--            <div class="input_box">-->
                    <!--              <input type="text" placeholder="채널명">-->
                    <!--            </div>-->
                    <!--            <div class="btn_area">-->
                    <!--              <button class="btn basic w_ss h_l">확인</button>-->
                    <!--            </div>-->
                    <!--            <small class="error_txt" style="display: none">URL Link를 입력 해 주세요.</small>-->
                    <!--          </div>-->
                </div>
            </div>
        </div>
        <!-- e: 페이지 타이틀 -->
        <!-- s: content_body -->
        <div class="content_body">
            <!-- s: item_list_sec-->
            <div class="item_list_sec">
                <!-- Dev: 상품 추가 wrap -->
                <div class="item_list_wrap none min" :class="{is_active : index === activeIndex}"
                     @click="activeIndex = index">
                    <div class="item_list_head min">
                        <div class="item_thumb">
                            <button>추가버튼</button>
                        </div>
                    </div>
                </div>
                <!-- Dev : 등록된 상품  -->
                <div class="registration_item_wrap">
                    <Container class="registration_item_area"
                               orientation="horizontal"
                               tag="div"
                               :drop-placeholder="dropPlaceholderOptions"
                               @drop="onDrop">
                        <Draggable class="item_list_wrap min" v-for="(item, i) in cardList" :key="'item' + i"
                                   :class="{is_active : i === activeIndex}" @click="activeIndex = i">
                            <div class="item_list_head min">
                                <div class="item_list_body">
                                    {{ item.file }}
                                </div>
                                <div class="delete_btn_area">
                                    <button class="delete_btn" @click="removeList()">삭제버튼</button>
                                </div>
                            </div>
                        </Draggable>
                    </Container>
                </div>
            </div>
            <!-- e: item_list_sec-->

            <!-- s: preview_sec -->
            <div class="preview_sec">
                <div class="preview_slide_time_area">
                    <!-- Dev : 시간 미입력시 is_error 클래스 추가          -->
                    <div class="input_area inline is_error">
                        <label>이미지 슬라이드 시간</label>
                        <div class="input_box">
                            <!-- Dev : default 값 5초 -->
                            <input type="number" placeholder="5">
                        </div>
                        <div class="input_txt">초</div>
                        <!-- Dev : 이미지가 총 재생되는 재생시간 -->
                        <div class="play_time">(총 재생 시간 : {{ vmdPlayTime.toFixed(2) }}초)</div>
                        <!-- Dev: 에러시 출력 -->
                        <small class="error_txt">슬라이드 시간을 입력 해 주세요.</small>
                    </div>
                </div>
                <!-- Dev : 우리매장 핫딜, 반값초이스 일시 preview_wrap -->
                <div class="preview_wrap basic" v-if="experience === 0">
                    <div class="upload_guide" role="button">
                        <img src="../../../assets/img/common/tooltip.png" alt="">
                        <p>파일 업로드 가이드</p>
                        <div class="layer_info">
                            <div class="info_area" v-for="(item, i) in tooltip" :key="'item' + i">
                                <div class="ttl">{{ item.ttl }}</div>
                                <div class="cnt">
                                    <p>{{ item.file }}</p>
                                    <p>{{ item.memory }}</p>
                                    <p>{{ item.display }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="preview_area">
                        <div class="swiper_nav_prev"></div>
                        <div class="swiper_nav_next"></div>
                        <div class="slide_area">
                            <div class="swiper_pagination"></div>
                            <swiper @swiper="setControlledSwiper"
                                    v-bind="swiperOptions"
                                    slidesPerView="auto"
                                    :spaceBetween="10"
                                    :loop="false"
                                    :pagination=" {
                                  el: '.swiper_pagination',
                                }"
                                    :navigation=" {
                              nextEl: '.swiper_nav_prev',
                              prevEl: '.swiper_nav_next'
                            }"
                            >
                                <swiper-slide>
                                    <!-- Dev : 등록된 파일이 없는 경우 -->
                                    <template v-if="videoFile">
                                        <div class="upload_btn_wrap">
                                            <div class="upload_btn" role="button">
                                                <div class="txt_area">
                                                    <p>지정된 포맷과 사이즈의 파일을 등록해 주세요</p>
                                                </div>
                                                <div class="btn_area">
                                                    <button type="button" class="btn basic w_smm h_m">파일 추가</button>
                                                    <input type="file" accept=".mp4" @change="onFileChange($event)">
                                                </div>
                                                <div class="info_area">
                                                    <p>
                                                        png/jpg, 1000*1000<br>
                                                        mp4, 1920*1080
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <!--Dev: 파일이 이미지일 경우-->
                                    <template v-else-if="!imgFile">
                                        <div class="model_img_wrap">
                                            <div class="model_img_box" style="background-image: url('')">
                                            </div>
                                            <div class="hover_btn_area">
                                                <!--  Dev: 영상 수정 버튼 클릭시 파일첨부 호출 -->
                                                <button class="modify_btn">
                                                    <img src="../../../assets/img/common/video_modify.png" alt="">
                                                    <input type="file" accept=".mp4" @change="onFileChange($event)">
                                                </button>
                                                <!--  Dev: 영상 삭제 버튼 클릭시 첨부 된 파일 삭제 -->
                                                <button class="delete_btn">삭제</button>
                                            </div>
                                        </div>
                                    </template>
                                    <!--Dev: 파일이 영상일 경우-->
                                    <template v-else>
                                        <div class="upload_video">
                                            <video class="video_box" style="background-color: #333">
                                            </video>
                                            <button class="video_play_btn play" @click="playVideo('play')"
                                                    v-if="playStatus">
                                                <img src="../../../assets/img/common/video_play_btn.png" alt="">
                                            </button>
                                            <button class="video_play_btn pause" @click="playVideo('pause')" v-else>
                                                <img src="../../../assets/img/common/video_pause_btn.png" alt="">
                                            </button>
                                            <div class="hover_btn_area">
                                                <!--  Dev: 영상 수정 버튼 클릭시 파일첨부 호출 -->
                                                <button class="modify_btn">
                                                    <img src="../../../assets/img/common/video_modify.png" alt="">
                                                    <input type="file" accept=".mp4" @change="onFileChange($event)">
                                                </button>
                                                <!--  Dev: 영상 삭제 버튼 클릭시 첨부 된 파일 삭제 -->
                                                <button class="delete_btn">삭제</button>
                                            </div>
                                        </div>
                                    </template>
                                </swiper-slide>
                                <swiper-slide>
                                    <!-- Dev : 등록된 파일이 없는 경우 -->
                                    <template v-if="videoFile">
                                        <div class="upload_btn_wrap">
                                            <div class="upload_btn" role="button">
                                                <div class="txt_area">
                                                    <p>지정된 포맷과 사이즈의 파일을 등록해 주세요</p>
                                                </div>
                                                <div class="btn_area">
                                                    <button type="button" class="btn basic w_smm h_m">파일 추가</button>
                                                    <input type="file" accept=".mp4" @change="onFileChange($event)">
                                                </div>
                                                <div class="info_area">
                                                    <p>
                                                        png/jpg, 1000*1000<br>
                                                        mp4, 1920*1080
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <!--Dev: 파일이 이미지일 경우-->
                                    <template v-else-if="!imgFile">
                                        <div class="model_img_wrap">
                                            <div class="model_img_box" style="background-image: url('')">
                                            </div>
                                            <div class="hover_btn_area">
                                                <!--  Dev: 영상 수정 버튼 클릭시 파일첨부 호출 -->
                                                <button class="modify_btn">
                                                    <img src="../../../assets/img/common/video_modify.png" alt="">
                                                    <input type="file" accept=".mp4" @change="onFileChange($event)">
                                                </button>
                                                <!--  Dev: 영상 삭제 버튼 클릭시 첨부 된 파일 삭제 -->
                                                <button class="delete_btn">삭제</button>
                                            </div>
                                        </div>
                                    </template>
                                    <!--Dev: 파일이 영상일 경우-->
                                    <template v-else>
                                        <div class="upload_video">
                                            <video class="video_box" style="background-color: #333">
                                            </video>
                                            <button class="video_play_btn play" @click="playVideo('play')"
                                                    v-if="playStatus">
                                                <img src="../../../assets/img/common/video_play_btn.png" alt="">
                                            </button>
                                            <button class="video_play_btn pause" @click="playVideo('pause')" v-else>
                                                <img src="../../../assets/img/common/video_pause_btn.png" alt="">
                                            </button>
                                            <div class="hover_btn_area">
                                                <!--  Dev: 영상 수정 버튼 클릭시 파일첨부 호출 -->
                                                <button class="modify_btn">
                                                    <img src="../../../assets/img/common/video_modify.png" alt="">
                                                    <input type="file" accept=".mp4" @change="onFileChange($event)">
                                                </button>
                                                <!--  Dev: 영상 삭제 버튼 클릭시 첨부 된 파일 삭제 -->
                                                <button class="delete_btn">삭제</button>
                                            </div>
                                        </div>
                                    </template>
                                </swiper-slide>
                                <swiper-slide>
                                    <!-- Dev : 등록된 파일이 없는 경우 -->
                                    <template v-if="videoFile">
                                        <div class="upload_btn_wrap">
                                            <div class="upload_btn" role="button">
                                                <div class="txt_area">
                                                    <p>지정된 포맷과 사이즈의 파일을 등록해 주세요</p>
                                                </div>
                                                <div class="btn_area">
                                                    <button type="button" class="btn basic w_smm h_m">파일 추가</button>
                                                    <input type="file" accept=".mp4" @change="onFileChange($event)">
                                                </div>
                                                <div class="info_area">
                                                    <p>
                                                        png/jpg, 1000*1000<br>
                                                        mp4, 1920*1080
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <!--Dev: 파일이 이미지일 경우-->
                                    <template v-else-if="!imgFile">
                                        <div class="model_img_wrap">
                                            <div class="model_img_box" style="background-image: url('')">
                                            </div>
                                            <div class="hover_btn_area">
                                                <!--  Dev: 영상 수정 버튼 클릭시 파일첨부 호출 -->
                                                <button class="modify_btn">
                                                    <img src="../../../assets/img/common/video_modify.png" alt="">
                                                    <input type="file" accept=".mp4" @change="onFileChange($event)">
                                                </button>
                                                <!--  Dev: 영상 삭제 버튼 클릭시 첨부 된 파일 삭제 -->
                                                <button class="delete_btn">삭제</button>
                                            </div>
                                        </div>
                                    </template>
                                    <!--Dev: 파일이 영상일 경우-->
                                    <template v-else>
                                        <div class="upload_video">
                                            <video class="video_box" style="background-color: #333">
                                            </video>
                                            <button class="video_play_btn play" @click="playVideo('play')"
                                                    v-if="playStatus">
                                                <img src="../../../assets/img/common/video_play_btn.png" alt="">
                                            </button>
                                            <button class="video_play_btn pause" @click="playVideo('pause')" v-else>
                                                <img src="../../../assets/img/common/video_pause_btn.png" alt="">
                                            </button>
                                            <div class="hover_btn_area">
                                                <!--  Dev: 영상 수정 버튼 클릭시 파일첨부 호출 -->
                                                <button class="modify_btn">
                                                    <img src="../../../assets/img/common/video_modify.png" alt="">
                                                    <input type="file" accept=".mp4" @change="onFileChange($event)">
                                                </button>
                                                <!--  Dev: 영상 삭제 버튼 클릭시 첨부 된 파일 삭제 -->
                                                <button class="delete_btn">삭제</button>
                                            </div>
                                        </div>
                                    </template>
                                </swiper-slide>
                            </swiper>
                        </div>
                        <!-- Dev : 제품정보가 등록 되었을때 -->
                        <!--                        <div class="product_wrap">-->
                        <!--                          <div class="product_name_area">-->
                        <!--                            <h3>삼성 갤럭시 탭 S7 128G Wi-Fi</h3>-->
                        <!--                          </div>-->
                        <!--                          <div class="product_sub_name_area">-->
                        <!--                            <p>나의 일상, 나의 세계를 그대로 보여주는 디스플레이</p>-->
                        <!--                          </div>-->
                        <!--                          <div class="product_info_box">-->
                        <!--                            &lt;!&ndash;   Dev : 핫딜 VMD 일때 &ndash;&gt;-->
                        <!--                            <dl class="info_hotdeal">-->
                        <!--                              <div class="retail_price">-->
                        <!--                                <dt>정상가</dt>-->
                        <!--                                <dd>-->
                        <!--                                  <strong>829,400</strong>원-->
                        <!--                                </dd>-->
                        <!--                              </div>-->
                        <!--                              <div>-->
                        <!--                                <dt>할인가</dt>-->
                        <!--                                <dd class="strong">-->
                        <!--                                  <span>인터넷/TV 가입 시</span>-->
                        <!--                                  <strong>408,000</strong>원-->
                        <!--                                </dd>-->
                        <!--                              </div>-->
                        <!--                              <div class="membership_price">-->
                        <!--                                <dt>멤버십 할인가</dt>-->
                        <!--                                <dd class="strong">-->
                        <!--                                  <strong>124,000</strong>원-->
                        <!--                                </dd>-->
                        <!--                              </div>-->
                        <!--                            </dl>-->
                        <!--                            &lt;!&ndash;   Dev : 반값초이스 일때 &ndash;&gt;-->
                        <!--                            &lt;!&ndash;                <dl class="info_halfprice">&ndash;&gt;-->
                        <!--                            &lt;!&ndash;                  <div class="normal">&ndash;&gt;-->
                        <!--                            &lt;!&ndash;                    <dt>정상가</dt>&ndash;&gt;-->
                        <!--                            &lt;!&ndash;                    <dd><strong>829,400</strong>원</dd>&ndash;&gt;-->
                        <!--                            &lt;!&ndash;                  </div>&ndash;&gt;-->
                        <!--                            &lt;!&ndash;                  <div>&ndash;&gt;-->
                        <!--                            &lt;!&ndash;                    <dt>일시납</dt>&ndash;&gt;-->
                        <!--                            &lt;!&ndash;                    <dd class="strong">&ndash;&gt;-->
                        <!--                            &lt;!&ndash;                      <span>인터넷/TV 가입 시</span>&ndash;&gt;-->
                        <!--                            &lt;!&ndash;                      <strong>800,000</strong>원&ndash;&gt;-->
                        <!--                            &lt;!&ndash;                    </dd>&ndash;&gt;-->
                        <!--                            &lt;!&ndash;                  </div>&ndash;&gt;-->
                        <!--                            &lt;!&ndash;                  <div>&ndash;&gt;-->
                        <!--                            &lt;!&ndash;                    <dt>할부납(36개월)</dt>&ndash;&gt;-->
                        <!--                            &lt;!&ndash;                    <dd class="strong">월 <strong>124,000</strong>원</dd>&ndash;&gt;-->
                        <!--                            &lt;!&ndash;                  </div>&ndash;&gt;-->
                        <!--                            &lt;!&ndash;                </dl>&ndash;&gt;-->
                        <!--                          </div>-->
                        <!--                          <div class="features_box">-->
                        <!--                            <div class="ttl">-->
                        <!--                              <p>제품 설명</p>-->
                        <!--                            </div>-->
                        <!--                            <div class="cnt">-->
                        <!--                              <ul>-->
                        <!--                                <li>화면을 가득 채우는 16:10 화면 디스플레이</li>-->
                        <!--                                <li>더 부드럽고 매끄러워진 화연 움직임</li>-->
                        <!--                                <li>세련된 컬러와 아노다이징 디자인, 견고한 바디</li>-->
                        <!--                              </ul>-->
                        <!--                            </div>-->
                        <!--                          </div>-->
                        <!--                        </div>-->

                        <!-- Dev : 제품정보가 등록되지 않았을때 -->
                        <div class="product_wrap_none">
                            <div class="product_name_area">
                                <!--  Dev : error시 is_error 추가 -->
                                <div class="input_area inline is_error">
                                    <label></label>
                                    <div class="input_box">
                                        <input type="text" placeholder="제목">
                                    </div>
                                    <small class="error_txt">제목을 입력해주세요.</small>
                                </div>
                            </div>
                            <div class="product_sub_name_area">
                                <div class="input_area inline">
                                    <label></label>
                                    <div class="input_box">
                                        <input type="text" placeholder="부제목">
                                    </div>
                                </div>
                            </div>
                            <div class="product_info_box">
                                <!--   Dev : 핫딜 VMD 일때 -->
                                <!--                <dl class="info_hotdeal">-->
                                <!--                  <div class="retail_price">-->
                                <!--                    <dt class="must_txt">정상가</dt>-->
                                <!--                    <dd>-->
                                <!--                      &lt;!&ndash;  Dev : error시 is_error 추가 &ndash;&gt;-->
                                <!--                      <div class="input_area inline is_error">-->
                                <!--                        <label></label>-->
                                <!--                        <div class="input_box">-->
                                <!--                          <input type="text" placeholder="0">-->
                                <!--                        </div>-->
                                <!--                        <div class="input_txt">원</div>-->
                                <!--                        <small class="error_txt">정상가를 입력해주세요.</small>-->
                                <!--                      </div>-->
                                <!--                    </dd>-->
                                <!--                  </div>-->
                                <!--                  <div>-->
                                <!--                    <dt>할인가</dt>-->
                                <!--                    <dd>-->
                                <!--                      <div class="input_area inline">-->
                                <!--                        <label></label>-->
                                <!--                        <div class="input_box">-->
                                <!--                          <input type="text" placeholder="0">-->
                                <!--                        </div>-->
                                <!--                        <div class="input_txt">원</div>-->
                                <!--                      </div>-->
                                <!--                    </dd>-->
                                <!--                  </div>-->
                                <!--                  <div class="membership_price">-->
                                <!--                    <dt class="must_txt">멤버십 할인가</dt>-->
                                <!--                    <dd>-->
                                <!--                      &lt;!&ndash;  Dev : error시 is_error 추가 &ndash;&gt;-->
                                <!--                      <div class="input_area inline is_error">-->
                                <!--                        <label></label>-->
                                <!--                        <div class="input_box">-->
                                <!--                          <input type="text" placeholder="0">-->
                                <!--                        </div>-->
                                <!--                        <div class="input_txt">원</div>-->
                                <!--                        <small class="error_txt">맴버십 할인가를 입력해주세요.</small>-->
                                <!--                      </div>-->
                                <!--                    </dd>-->
                                <!--                  </div>-->
                                <!--                </dl>-->
                                <!--   Dev : 반값초이스 일때 -->
                                <dl class="info_halfprice">
                                    <div class="normal">
                                        <dt class="must_txt">정상가</dt>
                                        <dd>
                                            <!--  Dev : error시 is_error 추가 -->
                                            <div class="input_area inline is_error">
                                                <label></label>
                                                <div class="input_box">
                                                    <input type="text" placeholder="0">
                                                </div>
                                                <div class="input_txt">원</div>
                                                <small class="error_txt">정상가를 입력해주세요.</small>
                                            </div>
                                        </dd>
                                    </div>
                                    <div>
                                        <dt class="must_txt">일시납</dt>
                                        <dd>
                                            <!--  Dev : error시 is_error 추가 -->
                                            <div class="input_area inline is_error">
                                                <label></label>
                                                <div class="input_box">
                                                    <input type="text" placeholder="0">
                                                </div>
                                                <div class="input_txt">원</div>
                                                <small class="error_txt">일시납을 입력해주세요.</small>
                                            </div>
                                        </dd>
                                    </div>
                                    <div>
                                        <dt>할부납</dt>
                                        <dd>
                                            <div class="input_area select inline">
                                                <select>
                                                    <option value="12">12개월</option>
                                                    <option value="24">24개월</option>
                                                    <option value="36">36개월</option>
                                                </select>
                                            </div>
                                            <div class="input_area inline">
                                                <label></label>
                                                <div class="input_box">
                                                    <input type="text" placeholder="0">
                                                </div>
                                                <div class="input_txt">원</div>
                                            </div>
                                        </dd>
                                    </div>
                                </dl>
                            </div>
                            <div class="features_box">
                                <div class="ttl">
                                    <p>제품 설명</p>
                                </div>
                                <div class="cnt">
                                    <ul>
                                        <li>
                                            <div class="input_area inline">
                                                <label></label>
                                                <div class="input_box">
                                                    <input type="text" placeholder="설명1">
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="input_area inline">
                                                <label></label>
                                                <div class="input_box">
                                                    <input type="text" placeholder="설명2">
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="input_area inline">
                                                <label></label>
                                                <div class="input_box">
                                                    <input type="text" placeholder="설명3">
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Dev : 만능체험대 일시 preview_wrap -->
                <div class="preview_wrap experience" v-if="experience === 1">
                    <div class="upload_guide" role="button">
                      <div class="tooltip_area">
                        <img src="../../../assets/img/common/tooltip.png" alt="">
                        <p>파일 업로드 가이드</p>
                      </div>
                    </div>
                    <div class="experience_wrap">
                        <div class="preview_area">
                            <div class="swiper_nav_prev"></div>
                            <div class="swiper_nav_next"></div>
                            <div class="slide_area">
                                <swiper @swiper="setControlledSwiper"
                                        slidesPerView="auto"
                                        :loop="false"
                                        :navigation=" {
                                    nextEl: '.swiper_nav_prev',
                                    prevEl: '.swiper_nav_next'
                                  }"
                                >
                                    <swiper-slide>
                                        <!-- Dev : 등록된 파일이 없는 경우 -->
                                        <template v-if="videoFile">
                                            <div class="upload_btn_wrap">
                                                <div class="upload_btn" role="button">
                                                    <div class="txt_area">
                                                        <p>지정된 포맷과 사이즈의 파일을 등록해 주세요</p>
                                                    </div>
                                                    <div class="btn_area">
                                                        <button type="button" class="btn basic w_smm h_m">파일 추가</button>
                                                        <input type="file" accept=".mp4" @change="onFileChange($event)">
                                                    </div>
                                                    <div class="info_area">
                                                        <p>
                                                            png/jpg, 1000*1000<br>
                                                            mp4, 1920*1080
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                        <!--Dev: 파일이 이미지일 경우-->
                                        <template v-else-if="!imgFile">
                                            <div class="model_img_wrap">
                                                <div class="model_img_box" style="background-image: url('')">
                                                </div>
                                                <div class="hover_btn_area">
                                                    <!--  Dev: 영상 수정 버튼 클릭시 파일첨부 호출 -->
                                                    <button class="modify_btn">
                                                        <img src="../../../assets/img/common/video_modify.png" alt="">
                                                        <input type="file" accept=".mp4" @change="onFileChange($event)">
                                                    </button>
                                                    <!--  Dev: 영상 삭제 버튼 클릭시 첨부 된 파일 삭제 -->
                                                    <button class="delete_btn">삭제</button>
                                                </div>
                                            </div>
                                        </template>
                                        <!--Dev: 파일이 영상일 경우-->
                                        <template v-else>
                                            <div class="upload_video">
                                                <video class="video_box" style="background-color: #333">
                                                </video>
                                                <button class="video_play_btn play" @click="playVideo('play')"
                                                        v-if="playStatus">
                                                    <img src="../../../assets/img/common/video_play_btn.png" alt="">
                                                </button>
                                                <button class="video_play_btn pause" @click="playVideo('pause')" v-else>
                                                    <img src="../../../assets/img/common/video_pause_btn.png" alt="">
                                                </button>
                                                <div class="hover_btn_area">
                                                    <!--  Dev: 영상 수정 버튼 클릭시 파일첨부 호출 -->
                                                    <button class="modify_btn">
                                                        <img src="../../../assets/img/common/video_modify.png" alt="">
                                                        <input type="file" accept=".mp4" @change="onFileChange($event)">
                                                    </button>
                                                    <!--  Dev: 영상 삭제 버튼 클릭시 첨부 된 파일 삭제 -->
                                                    <button class="delete_btn">삭제</button>
                                                </div>
                                            </div>
                                        </template>
                                    </swiper-slide>
                                    <swiper-slide>
                                        <!-- Dev : 등록된 파일이 없는 경우 -->
                                        <template v-if="!videoFile">
                                            <div class="upload_btn" role="button">
                                                <div class="txt_area">
                                                    <p>지정된 포맷과 사이즈의 파일을 등록해 주세요</p>
                                                </div>
                                                <div class="btn_area">
                                                    <button type="button" class="btn basic w_smm h_m">파일 추가</button>
                                                    <input type="file" accept=".mp4" @change="onFileChange($event)">
                                                </div>
                                                <div class="info_area">
                                                    <p>
                                                        png/jpg, 1000*1000<br>
                                                        mp4, 1920*1080
                                                    </p>
                                                </div>
                                            </div>
                                        </template>
                                        <!--Dev: 파일이 이미지일 경우-->
                                        <template v-else-if="imgFile">
                                            <div class="model_img_wrap">
                                                <div class="model_img_box" style="background-image: url('')">
                                                </div>
                                                <div class="hover_btn_area">
                                                    <!--  Dev: 영상 수정 버튼 클릭시 파일첨부 호출 -->
                                                    <button class="modify_btn">
                                                        <img src="../../../assets/img/common/video_modify.png" alt="">
                                                        <input type="file" accept=".mp4" @change="onFileChange($event)">
                                                    </button>
                                                    <!--  Dev: 영상 삭제 버튼 클릭시 첨부 된 파일 삭제 -->
                                                    <button class="delete_btn">삭제</button>
                                                </div>
                                            </div>
                                        </template>
                                        <!--Dev: 파일이 영상일 경우-->
                                        <template v-else>
                                            <div class="upload_video">
                                                <video class="video_box" style="background-color: #333">
                                                </video>
                                                <button class="video_play_btn play" @click="playVideo('play')"
                                                        v-if="playStatus">
                                                    <img src="../../../assets/img/common/video_play_btn.png" alt="">
                                                </button>
                                                <button class="video_play_btn pause" @click="playVideo('pause')" v-else>
                                                    <img src="../../../assets/img/common/video_pause_btn.png" alt="">
                                                </button>
                                                <div class="hover_btn_area">
                                                    <!--  Dev: 영상 수정 버튼 클릭시 파일첨부 호출 -->
                                                    <button class="modify_btn">
                                                        <img src="../../../assets/img/common/video_modify.png" alt="">
                                                        <input type="file" accept=".mp4" @change="onFileChange($event)">
                                                    </button>
                                                    <!--  Dev: 영상 삭제 버튼 클릭시 첨부 된 파일 삭제 -->
                                                    <button class="delete_btn">삭제</button>
                                                </div>
                                            </div>
                                        </template>
                                    </swiper-slide>
                                    <swiper-slide>
                                        <!-- Dev : 등록된 파일이 없는 경우 -->
                                        <template v-if="!videoFile">
                                            <div class="upload_btn" role="button">
                                                <div class="txt_area">
                                                    <p>지정된 포맷과 사이즈의 파일을 등록해 주세요</p>
                                                </div>
                                                <div class="btn_area">
                                                    <button type="button" class="btn basic w_smm h_m">파일 추가</button>
                                                    <input type="file" accept=".mp4" @change="onFileChange($event)">
                                                </div>
                                                <div class="info_area">
                                                    <p>
                                                        png/jpg, 1000*1000<br>
                                                        mp4, 1920*1080
                                                    </p>
                                                </div>
                                            </div>
                                        </template>
                                        <!--Dev: 파일이 이미지일 경우-->
                                        <template v-else-if="!imgFile">
                                            <div class="model_img_wrap">
                                                <div class="model_img_box" style="background-image: url('')">
                                                </div>
                                                <div class="hover_btn_area">
                                                    <!--  Dev: 영상 수정 버튼 클릭시 파일첨부 호출 -->
                                                    <button class="modify_btn">
                                                        <img src="../../../assets/img/common/video_modify.png" alt="">
                                                        <input type="file" accept=".mp4" @change="onFileChange($event)">
                                                    </button>
                                                    <!--  Dev: 영상 삭제 버튼 클릭시 첨부 된 파일 삭제 -->
                                                    <button class="delete_btn">삭제</button>
                                                </div>
                                            </div>
                                        </template>
                                        <!--Dev: 파일이 영상일 경우-->
                                        <template v-else>
                                            <div class="upload_video">
                                                <video class="video_box" style="background-color: #333">
                                                </video>
                                                <button class="video_play_btn play" @click="playVideo('play')"
                                                        v-if="playStatus">
                                                    <img src="../../../assets/img/common/video_play_btn.png" alt="">
                                                </button>
                                                <button class="video_play_btn pause" @click="playVideo('pause')" v-else>
                                                    <img src="../../../assets/img/common/video_pause_btn.png" alt="">
                                                </button>
                                                <div class="hover_btn_area">
                                                    <!--  Dev: 영상 수정 버튼 클릭시 파일첨부 호출 -->
                                                    <button class="modify_btn">
                                                        <img src="../../../assets/img/common/video_modify.png" alt="">
                                                        <input type="file" accept=".mp4" @change="onFileChange($event)">
                                                    </button>
                                                    <!--  Dev: 영상 삭제 버튼 클릭시 첨부 된 파일 삭제 -->
                                                    <button class="delete_btn">삭제</button>
                                                </div>
                                            </div>
                                        </template>
                                    </swiper-slide>
                                </swiper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- e: preview_sec -->
        </div>
        <!-- e : content_body    -->

        <!-- s: content_foot -->
        <div class="content_foot">
            <div class="btn_group center">
                <!-- Dev : 첨부파일에 대한 부분만 리셋        -->
                <button class="btn basic w_m h_l">목록</button>
                <!-- Dev : 클릭 시 메인 영상 등록: 파일 첨부 반영  -->
                <button v-if="!imgFile" class="btn strong w_m h_l">수정</button>
                <button v-else class="btn strong w_m h_l" @click="registVmd()">저장</button>
            </div>
        </div>
        <!-- e : content_foot  -->
    </div>
    <Dialog :isOpen="deleteDialogState" :yes="'삭제'" :cancel="'취소'" v-on:cancelDialog="deleteDialogState = false"
            @yesDialog="deleteVmd()">
        <template #body>
            <h3>삭제 확인</h3>
            <p>해당 VMD를 삭제하시겠습니까?<br>
                변경된 내용은 채널에서 “발행하기”를<br>
                진행해야 Tablet에 반영됩니다.
            </p>
        </template>
    </Dialog>
</template>

<script>
import {Container, Draggable} from "vue3-smooth-dnd";
import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/swiper.min.css';
import "swiper/components/pagination/pagination.min.css";
import SwiperCore, {Autoplay, Navigation, Pagination} from 'swiper/core';
import moment from "moment";
import getBlobDuration from "get-blob-duration";
import Dialog from "@/components/dialog";


SwiperCore.use([Autoplay, Navigation, Pagination]);

export default {
    name: "new-index",
    layout: 'new-admin',
    components: {
        Container: Container,
        Draggable: Draggable,
        Swiper,
        SwiperSlide,
        Dialog,
    },
    data() {
        return {
            swiperOptions: {},
            cardList: [
                {
                    file: "삼성\n" +
                            "갤럭시탭 S7",
                },
                {
                    file: "삼성 갤럭시\n" +
                            "S20",
                },
                {
                    file: "애플\n" +
                            "아이패드아이패드아이패드아이패드아이패드아이패드",
                },
                {
                    file: "애플\n" +
                            "아이패드아이패드아이패드아이패드아이패드아이패드",
                },
            ],
            dropPlaceholderOptions: {
                className: 'drop-preview',
                animationDuration: '150',
                showOnTop: true
            },
            videoFile: true,
            imgFile: true,
            isActive: false,
            activeIndex: 0,
            controlledSwiper: null,
            playStatus: true,
            vmdPlayTime: 0,
            experience: 0,
            validation: {
                name: true,
                normalPrice: true,
                specialPrice: true,
                temporaryPrice: true,
                retailPrice: true,
                discountedPrice: true,
                membershipPrice: true
            },
            deleteDialogState: false,
            registrationDialogState: false,
            tooltip: [
                {
                    ttl: '이미지 파일형식',
                    file: 'JPG, GIF',
                    memory: '용량 2MB 이하',
                    display: '해상도 추후 결정',
                },
                {
                    ttl: '영상 파일형식',
                    file: 'MP4, AVI',
                    memory: '용량 80MB 이하',
                    display: '해상도 1920*1080',
                },
            ],

            channel_root_key: 'channel',
            channel: {},
        }
    },
    methods: {
        onFileChange(e, index) {
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            let file = files[0];

            const isImage = this.isImage(file);
            const isVideo = this.isVideo(file);
            const maxImageSize = 2 * 1024 * 1024;
            const maxVideoSize = 80 * 1024 * 1024;
            if (!isImage && !isVideo) {
                this.toastDanger('이미지는 .jpg, .gif, .png, 동영상은 .mp4만 지원합니다.');
                return;
            }
            if (isImage) {
                if (maxImageSize < file.size) {
                    this.toastDanger('이미지 용량은 2MB 미만으로 등록해주세요.');
                    return;
                }
            } else if (isVideo) {
                const existsVideo = this.vmd.files.find((file) => {
                    if (file instanceof File) {
                        if (this.isVideo(file)) {
                            return true;
                        }
                    } else if (typeof file === 'string') {
                        if (file.toLowerCase().indexOf('.mp4') >= 0) {
                            return true;
                        }
                    }
                    return false;
                });
                /*if(existsVideo){
                    this.toastDanger('동영상은 최대 1개만 등록 가능합니다.');
                    return;
                }*/
                if (maxVideoSize < file.size) {
                    this.toastDanger('동영상 용량은 80MB 미만으로 등록해주세요.');
                    return;
                }
            }
            this.vmd.files[index] = file;
            this.countPlayTime();
        },
        onDrop(dropResult) {

        },
        setControlledSwiper(swiper) {
            this.controlledSwiper = swiper;
        },
        playVideo(status) {
            const video = document.querySelector('.video_box');
            if (status === 'play') {
                video.play();
                this.playStatus = false;
            } else {
                video.pause();
                this.playStatus = true;
            }
        },
        countPlayTime() {
            this.vmdPlayTime = 0;
            console.log(this.vmd.filesPlayTime);
            if (this.vmd && this.vmd.files) {
                for (const file of this.vmd.files) {
                    const isVideo = this.isVideo(file);
                    const isImage = this.isImage(file);
                    if (isVideo) {
                        try {
                            console.log(file);
                            const blob = URL.createObjectURL(file);
                            getBlobDuration(blob).then((i) => {
                                this.vmdPlayTime += i;
                                console.log(i, '더햇음', this.vmdPlayTime);
                            });
                        } catch (ignore) {
                            fetch(file).then(res => res.blob()).then(blob => {
                                getBlobDuration(blob).then((i) => {
                                    this.vmdPlayTime += i;
                                });
                            });
                        }
                    } else if (isImage) {
                        this.vmdPlayTime += this.vmd.imagePlayTime;
                    } else {
                        if (file) {
                            //console.log(file);
                            const url = file.toString().toLowerCase();
                            if (url.indexOf('.mp4')) {
                                try {
                                    const blob = URL.createObjectURL(file);
                                    getBlobDuration(blob).then((i) => {
                                        this.vmdPlayTime += i;
                                    });
                                } catch (ignore) {
                                    fetch(file).then(res => res.blob()).then(blob => {
                                        getBlobDuration(blob).then((i) => {
                                            this.vmdPlayTime += i;
                                        });
                                    });
                                }

                            } else {
                                this.vmdPlayTime += this.vmd.imagePlayTime;
                            }
                        }
                    }
                }
            }
        },
        deleteVmd() {
            this.deleteDialogState = false;
            this.toastSuccess('VMD가 삭제 되었습니다.');
            // this.redirect('/vmd');
        },
        removeList() {
            console.log('삭제')
            this.deleteDialogState = true;
        },
        registVmd() {
            this.toastSuccess('VMD가 등록되었습니다.');
        }
    },
    created() {
        this.modifyHash = this.$route.params.hash;

        // hash없이 vmd에 들어올 수 없다. 채널로 이동한다.
        if(!this.modifyHash){
            this.redirect('/channel');
        }

        this.$firebase.database().ref(this.channel_root_key).child(this.modifyHash).on('value', (sn) => {
            this.channel = sn.val() || {};
            console.log(this.channel);
        })

    }
}
</script>

<style scoped>

</style>